<template>
    <div class="transaction-table">
        <div class="row">
            <div class="col-lg-12">
                <div class="">
                    <div class="d-sm-flex align-items-center justify-content-between">
                        <h3 class="mb-2">Transactions list</h3>
                    </div>
                    <div class="transaction-table-wrap overflow-auto">
                        <table class="table bg-transparent table-orders table-borderless">
                            <tbody>
                                <template v-for="(transaction, orIndex) in paginatedOrders">
                                    <tr :key="orIndex">
                                        <td>Account holder: <strong>{{transaction.account_name}}</strong></td>
                                        <td>Bank: <strong>{{transaction.bank_name}}</strong></td>
                                        <td>Amount: <strong>${{transaction.amount}}</strong></td>
                                        <td>Status: <strong class="text-uppercase">{{transaction.status}}</strong></td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        <p class="text-center bg-white py-4" v-if="!paginatedOrders.length">No transaction to show</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <b-pagination
                v-model="paginateOptions.currentPage"
                :total-rows="rows"
                :per-page="paginateOptions.perPage"
                @input="onPageChange"
                class="mt-4"
                >
                </b-pagination>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'transaction-table',
    data() {
        return {
            paginateOptions:{
                perPage: 4,
                currentPage: 1,
                startIndex: 0,
                endIndex: 4
            },
        };
    },
    computed:{
        transactions() {
            return this.$store.state.transaction.transactions
        },
        rows() {
            return this.transactions.length
        },
        paginatedOrders(){
            return this.transactions.slice(
                this.paginateOptions.startIndex,
                this.paginateOptions.endIndex
            );
        },
    },
    methods:{
        onPageChange() {
            this.paginateOptions.startIndex = (this.paginateOptions.currentPage - 1) * this.paginateOptions.perPage;
            this.paginateOptions.endIndex = (this.paginateOptions.currentPage - 1) * this.paginateOptions.perPage + this.paginateOptions.perPage;
        },
    },
    mounted(){
        this.$store.dispatch("transaction/fetchTransactions")
    }

}
</script>

<style scoped>

.transaction-table .table tr{
    background: #fff;
    border-bottom: 10px solid #f7f8f9;
    border-top: 10px solid #f7f8f9;
}
.transaction-table .table tr:hover{
    background: #f1f5f7;
    transform: all .5s ease-in-out;
    cursor: pointer;
}

.transaction-table .table td, .table th{
    padding: 1.5rem 1rem;
}
</style>
